<template>
  <div>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="faultRecords" @clear="restTime">
      <template slot="adSearch">
        <vm-search :label="$t('event.dtuNumber')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
        <vm-search :label="$t('event.faultType')">
          <el-input v-model.trim="search.faultDesc" :placeholder="$t('event.faultType')" clearable></el-input>
        </vm-search>
        <vm-search :label="$t('event.faultTime')">
          <el-date-picker v-model="dateRange" :picker-options="pickerOptions" value-format="yyyy-MM-dd HH:mm:ss"
                          type="datetimerange" :unlink-panels="true" :range-separator="$t('common.to')"
                          :start-placeholder="$t('common.startDate')" :end-placeholder="$t('common.endDate')"
                          :default-time="['00:00:00', '23:59:59']" @change="handleDateChange" :clearable="false">
          </el-date-picker>
        </vm-search>
      </template>
      <template slot="toolbar">
        <el-button :loading="exportLoading" class="greenBtn" @click="exportExcel">
          {{$t("event.export")}}
        </el-button>
      </template>
      <table-column prop="faultCode" :label="$t('event.faultNo')" width="70px" align="center"></table-column>
      <table-column prop="elevatorNo" :label="$t('event.elevatorNo')" width="120px" align="center"></table-column>
      <table-column prop="elevatorName" :label="$t('event.elevatorName')" :width="160"></table-column>
      <table-column prop="dtuCode" :label="$t('event.dtuNumber')" width="120px" align="center"></table-column>
      <table-column prop="nodeCode" :label="$t('event.node')" width="70px" align="center"></table-column>
      <table-column prop="faultDesc" :label="$t('event.faultType')">
        <template #default="scope">
          <span v-if="scope.row.faultDesc === '000000'">{{$t("workOrder.unKnowFault")}}</span>
          <span v-else>{{scope.row.faultDesc}}</span>
        </template>
      </table-column>
      <table-column prop="faultTime" :label="$t('event.faultTime')" width="160px" align="center"></table-column>
      <table-column v-if="false" prop="boardFaultTime" :label="$t('event.boardFaultTime')" width="160px"
                       align="center"></table-column>
      <table-column prop="faultFloorCode" :label="$t('event.faultFloorCode')" width="120px"
                       align="center"></table-column>
      <table-column prop="displayFaultFloor" :label="$t('event.displayFaultFloor')" width="90px"
                       align="center"></table-column>
      <table-column v-if="showOrgId" :label="$t('event.data')" width="60px" align="center">
        <template #default="scope">
          <el-popover v-if="scope.row.note !== '' && scope.row.note !== null" placement="top-start" width="400"
                      trigger="hover" :content="scope.row.note" style="color: #0747FD;">
            <div style="width: 100%">
              原始数据包:{{scope.row.note}}
            </div>
            <div style="width: 100%;padding-top: 10px"
                 v-if="scope.row.floorCode !== '' && scope.row.floorCode !== null">
              <span>物理楼层:{{scope.row.floorCode}}</span>
              <span>楼层显示:{{scope.row.displayFloor}}</span>
            </div>
            <div style="width: 100%;padding-top: 10px" v-if="scope.row.liftUp !== '' && scope.row.liftUp !== null">
              <div>
                <span>上行:{{scope.row.liftUp}}</span>
                <span>下行:{{scope.row.liftDown}}</span>
                <span>运行:{{scope.row.liftRun}}</span>
                <span>门区:{{scope.row.doorRegion}}</span>
                <span>开门中:{{scope.row.doorOpening}}</span>
                <span>关门中:{{scope.row.doorClosing}}</span>
                <span>开门到位:{{scope.row.doorOpened}}</span>
                <span>关门到位:{{scope.row.doorClosed}}</span>
              </div>
              <div style="padding-top: 5px">
                <span>总门锁:{{scope.row.totalDoorLock}}</span>
                <span>厅门锁:{{scope.row.officeDoorLock}}</span>
                <span>满载:{{scope.row.fullLoad}}</span>
                <span>超载:{{scope.row.overLoad}}</span>
                <span>车厢叫车:{{scope.row.carriageCall}}</span>
                <span>开门按钮:{{scope.row.openButton}}</span>
                <span>轿厢报警:{{scope.row.carAlarm}}</span>
                <span>群控:{{scope.row.groupControl}}</span>
              </div>
              <div style="padding-top: 5px">
                <span>前门光幕:{{scope.row.frontDoorCurtain}}</span>
                <span>后门光幕:{{scope.row.backDoorCurtain}}</span>
                <span>上平层:{{scope.row.upperLayer}}</span>
                <span>下平层:{{scope.row.lowerLayer}}</span>
              </div>
            </div>
            <div style="width: 100%;padding-top: 10px" v-if="scope.row.overhaul !== '' && scope.row.overhaul !== null">
              <div>
                <span>检修:{{scope.row.overhaul}}</span>
                <span>司机:{{scope.row.driver}}</span>
                <span>锁梯:{{scope.row.lockLadder}}</span>
                <span>VIP:{{scope.row.vip}}</span>
                <span>消防返回:{{scope.row.fireReturn}}</span>
                <span>消防员:{{scope.row.firefighter}}</span>
                <span>安全回路通:{{scope.row.safetyCircuit}}</span>
                <span>正常（非故障）:{{scope.row.normal}}</span>
              </div>
              <div style="padding-top: 5px">
                <span>地震运行:{{scope.row.seismicOperation}}</span>
                <span>应急电源运行:{{scope.row.emergencySupply}}</span>
                <span>后备电源运行:{{scope.row.backupPowerSupply}}</span>
                <span>抱闸力检测:{{scope.row.brakeForceDetection}}</span>
                <span>井道学习:{{scope.row.hoistwayLearning}}</span>
                <span>测试运行:{{scope.row.testRun}}</span>
              </div>
              <div style="padding-top: 5px">
                <span>网关掉电:{{scope.row.gatewayPowerDown}}</span>
                <span>电梯离线:{{scope.row.offline}}</span>
                <span>警铃报警:{{scope.row.alarmBellAlarm}}</span>
              </div>
            </div>
            <div style="width: 100%;padding-top: 10px"
                 v-if="scope.row.faultFloorCode !== '' && scope.row.faultFloorCode !== null">
              <span>故障物理楼层:{{scope.row.faultFloorCode}}</span>
              <span>故障显示楼层:{{scope.row.displayFaultFloor}}</span>
              <span>故障码:{{scope.row.faultCode}}</span>
              <span>故障子码:{{scope.row.faultSubCode}}</span>
              <span>故障发生时间:{{scope.row.faultTime}}</span>
            </div>
            <div style="width: 100%;padding-top: 10px"
                 v-if="scope.row.terminalTime !== '' && scope.row.terminalTime !== null">
              <div>终端时间戳:{{scope.row.terminalTime}}</div>
            </div>
            <div style="width: 100%;padding-top: 10px" v-if="scope.row.runSpeed !== '' && scope.row.runSpeed !== null">
              <span>电梯速度:{{scope.row.runSpeed}}</span>
              <span>电梯高度:{{scope.row.runHigh}}</span>
            </div>
            <div style="width: 100%;padding-top: 10px"
                 v-if="scope.row.levelingErrorValue !== '' && scope.row.levelingErrorValue !== null">
              <span>平层误差值:{{scope.row.levelingErrorValue}}</span>
            </div>

            <i slot="reference" class="el-icon-warning"></i>
          </el-popover>
        </template>
      </table-column>

    </vm-table>
  </div>
</template>
<script>

  import auth from "@/util/auth";

  export default {
    data() {
      return {
        showOrgId: auth.getUsername() === "admin" || auth.getUsername() === "superAdmin",
        exportLoading: false,
        loading: false,
        dateRange: [],
        nowTime: "",
        aogTime: "",
        search: {
          filter: "",
          faultDesc: "",
          startTime: "",
          endTime: "",
        },
        pickerOptions: {
          disabledDate: function(time) {
            return time.getFullYear() < 2022;
          },
        },
      };
    },
    mounted() {
      this.getDate();
      this.getList(1);
    },
    methods: {
      getList(pageNum) {
        this.loading = true;
        this.$refs.vmTable.getList(pageNum);
        this.loading = false;
      },
      restTime() {
        this.dateRange = [];
        this.search.startTime = this.aogTime;
        this.search.endTime = this.nowTime;
        this.dateRange.push(this.aogTime, this.nowTime);
      },
      getDate() {
        let now = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59);
        let ago = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 62 * 3, 0, 0, 0);
        this.nowTime = this.getTime(now);
        this.aogTime = this.getTime(ago);
        this.dateRange.push(this.aogTime, this.nowTime);
        this.search.startTime = this.aogTime;
        this.search.endTime = this.nowTime;
      },
      getTime(now) {
        var year = now.getFullYear(); //得到年份
        var month = now.getMonth(); //得到月份
        var date = now.getDate(); //得到日期
        month = month + 1;
        month = month.toString().padStart(2, "0");
        date = date.toString().padStart(2, "0");

        var hour = now.getHours();
        var minute = now.getMinutes();
        var second = now.getSeconds();
        hour = hour.toString().padStart(2, "0");
        minute = minute.toString().padStart(2, "0");
        second = second.toString().padStart(2, "0");

        let yearTime = `${year}-${month}-${date}`;
        let hourTime = `${hour}:${minute}:${second}`;

        return yearTime + " " + hourTime;
      },
      exportExcel() {
        if (this.dateRange.length === 0) {
          this.$message.error(this.$t("event.tip.faultTime"));
          return;
        }
        this.exportLoading = true;
        let fullTime = [];
        for (let i = 0; i < this.dateRange.length; i++) {
          let fullYear = this.dateRange[i].split("-");
          let year = fullYear[0];
          let month = fullYear[1];
          let day = fullYear[2];

          let date = new Date(year, month, day);
          fullTime.push(date.getTime());
        }
        console.log((fullTime[1] - fullTime[0]) / (24 * 3600 * 1000));
        if (Math.ceil((fullTime[1] - fullTime[0]) / (24 * 3600 * 1000)) > 62 * 3) {
          this.$message.error(this.$t("event.tip.faultTime"));
          return;
        }
        let params = {
          startTime: this.dateRange != null ? this.dateRange[0] : "",
          endTime: this.dateRange != null ? this.dateRange[1] : "",
          filter: this.search.filter,
          faultDesc: this.search.faultDesc,
          isCn: this.$i18n.isCn,
        };
        this.$api
          .exportFile("faultRecords/export", params, 60)
          .then(res => {
            console.log(res.data);
            this.exportLoading = false;
            let blob = new Blob([res.data]);
            let fileName = this.$t("event.faultRecordFileName");
            this.$message.success("成功");
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              navigator.msSaveBlob(blob, fileName);
            } else {
              let a = document.createElement("a");
              a.href = URL.createObjectURL(blob);
              a.download = fileName;
              a.style.display = "none";
              document.body.appendChild(a);
              a.click();
              URL.revokeObjectURL(a.href);
              document.body.removeChild(a);
            }
          })
          .catch(() => {
            this.exportLoading = false;
          });
      },
      handleDateChange(dateRange) {
        if (dateRange != null) {
          this.search.startTime = dateRange[0];
          this.search.endTime = dateRange[1];
          if (dateRange.length > 0) {
            let fullTime = [];
            for (let i = 0; i < dateRange.length; i++) {
              let time = dateRange[i].split(" ");

              let fullYear = time[0].split("-");
              let year = fullYear[0];
              let month = fullYear[1];
              let day = fullYear[2];

              let fullDay = time[1].split(":");
              let hour = fullDay[0];
              let minute = fullDay[1];
              let second = fullDay[2];

              let date = new Date(year, month, day, hour, minute, second);
              fullTime.push(date.getTime());
            }
            if (Math.ceil((fullTime[1] - fullTime[0]) / (24 * 3600 * 1000)) > 62 * 3) {
              this.$message.error(this.$t("event.tip.faultTime"));
              this.dateRange = [this.aogTime, this.nowTime];
              this.search.startTime = this.aogTime;
              this.search.endTime = this.nowTime;
            }
          }
        } else {
          this.search.startTime = this.aogTime;
          this.search.endTime = this.nowTime;
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
.el-picker-panel__footer .el-picker-panel__link-btn.el-button--text {
  display: none;
}
</style>
