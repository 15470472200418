<template>
  <div>
    <vm-table
      ref="vmTable"
      v-loading="loading"
      :filter.sync="search"
      url="inshAlarm"
      @clear="restTime">
      <template slot="adSearch">
        <vm-search :label="$t('event.dtuNumber')">
          <el-input v-model.trim="search.dtuCode" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
        <vm-search :label="$t('event.faultTime')">
          <el-date-picker
            v-model="dateRange"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            :unlink-panels="true"
            :range-separator="$t('common.to')"
            :start-placeholder="$t('common.startDate')"
            :end-placeholder="$t('common.endDate')"
            :default-time="['00:00:00', '23:59:59']"
            :clearable="false"
            @change="handleDateChange">
          </el-date-picker>
        </vm-search>
      </template>
      <table-column prop="elevatorNo" :label="$t('event.elevatorNo')" width="120px" align="center"></table-column>
      <table-column prop="elevatorName" :label="$t('event.elevatorName')"></table-column>
      <table-column prop="dtuCode" :label="$t('event.dtuNumber')" width="120px" align="center"></table-column>
      <table-column prop="faultSn" :label="$t('event.faultSn')" width="180px" align="center"></table-column>
      <table-column prop="faultType" :label="$t('event.faultNo')" width="120px" align="center"></table-column>
      <table-column prop="faultName" :label="$t('potentialFault.faultDesc')" width="180px" align="center"></table-column>
      <table-column prop="faultFloor" :label="$t('event.faultFloor')" width="120px" align="center"></table-column>
      <table-column prop="faultStartTime" :label="$t('event.faultTime')" width="180px" align="center"></table-column>
      <table-column prop="faultRecoverTime" :label="$t('event.faultRecoverTime')" width="180px" align="center"></table-column>
    </vm-table>
  </div>
</template>
<script>


  export default {
    data() {
      return {
        loading: false,
        dateRange: [],
        search: {
          filter: "",
          faultDesc: "",
          startTime: "",
          endTime: "",
        },
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getList(pageNum) {
        this.loading = true;
        this.$refs.vmTable.getList(pageNum);
        this.loading = false;
      },
      restTime() {
        this.dateRange = [];
        this.search.startTime = "";
        this.search.endTime = "";
      },


      handleDateChange(dateRange) {
        if (dateRange != null) {
          this.search.startTime = dateRange[0];
          this.search.endTime = dateRange[1];
        } else {
          this.search.startTime = "";
          this.search.endTime = "";
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
.el-picker-panel__footer .el-picker-panel__link-btn.el-button--text {
  display: none;
}
</style>
